import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: () => import(/* webpackChunkName: "login_home_wel" */ '../components/Login') },
  {
    path: '/home',
    component: () => import(/* webpackChunkName: "login_home_wel" */ '../components/Home'),
    redirect: '/welcome',
    children: [
      { path: '/welcome', component: () => import(/* webpackChunkName: "login_home_wel" */ '../components/Welcome') },
      { path: '/admin', component: () => import(/* webpackChunkName: "admin" */ '../components/user/Admin') },
      { path: '/roles', component: () => import(/* webpackChunkName: "admin" */ '../components/user/Roles') },
      { path: '/product', component: () => import(/* webpackChunkName: "product" */ '../components/product/Product') },
      { path: '/product/add', component: () => import(/* webpackChunkName: "product" */ '../components/product/ProductAdd') },
      { path: '/product/edit/:id', component: () => import(/* webpackChunkName: "product" */ '../components/product/ProductEdit') },
      { path: '/category', component: () => import(/* webpackChunkName: "product" */ '../components/product/Category') },
      { path: '/markets', component: () => import(/* webpackChunkName: "product" */ '../components/product/Markets') },
      { path: '/project', component: () => import(/* webpackChunkName: "product" */ '../components/product/Project') },

      { path: '/class', component: () => import(/* webpackChunkName: "company" */ '../components/company/Class') },
      { path: '/class/edit/:id', component: () => import(/* webpackChunkName: "company" */ '../components/company/ClassEdit') },

      { path: '/news', component: () => import(/* webpackChunkName: "news" */ '../components/company/News') },
      { path: '/news/add', component: () => import(/* webpackChunkName: "news" */ '../components/company/NewsForm') },
      { path: '/news/edit/:id', component: () => import(/* webpackChunkName: "news" */ '../components/company/NewsForm'), hidden: true },

      { path: '/setting', component: () => import(/* webpackChunkName: "setting" */ '../components/setting/Setting') },
      { path: '/map', component: () => import(/* webpackChunkName: "setting" */ '../components/setting/Map') },
      { path: '/swiper', component: () => import(/* webpackChunkName: "setting" */ '../components/setting/Swiper') },
      { path: '/message', component: () => import(/* webpackChunkName: "login_home_wel" */ '../components/setting/Message') },
      { path: '/lianxi', component: () => import(/* webpackChunkName: "setting" */ '../components/setting/Lianxi') }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 导航守卫
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从哪个路径跳转过来
  // next 是一个函数，表示放行
  if (to.path === '/login') return next()
  // 获取token
  const tokenStr = window.sessionStorage.getItem('token')
  if (!tokenStr) return next('/login')
  next()
})

export default router
