import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
// import './plugins/element.js'
import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
import './assets/css/global.css'
import './assets/fonts/iconfont.css'

// 富文本编辑器
import TEditor from '@/components/TEditor.vue'
Vue.component('TEditor', TEditor)

// 全局上传地址
const globalUrlapi = 'http://adminapi.fclvision.com'

// axios
axios.defaults.baseURL = globalUrlapi

// axios请求拦截器
axios.interceptors.request.use(config => {
  NProgress.start()
  config.headers.Authorization = window.sessionStorage.getItem('token')
  if (config.url === 'login') {
    delete config.headers.Authorization
  } else if (config.url === 'captcha') {
    delete config.headers.Authorization
  }
  return config
})

axios.interceptors.response.use(config => {
  NProgress.done()
  return config
})

Vue.prototype.$http = axios
Vue.prototype.globalUrlapi = globalUrlapi

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
